const search = {
  tab: {
    sms: "SMSメッセージ",
    qr: "QRコード(イメージ)",
  },
  summary: {
    analysis: "分析結果",
    scan_at: "最近のスキャン日",
    status: "状態",
    tags: "タギング",
    issuer: "発行者",
    subject: "発行対象",
    not_before: "発行日",
    not_after: "満了日",
    version: "TLS バージョン",
    judgment_basis: "最終判断根拠(By ‘Generative AI’)",
    analysis_by_engine: "エンジン別分析結果及び判断根拠",
    detection: "エンジンごとの検出情報",
    detection_invaild_id: "アイテムID情報が無効です。",
    screenshot: "スクリーンショット",
    doamin_title: "ドメイン",
    domain: "ドメイン名",
    domain_info: "ドメイン情報",
    registered_info: "登録者情報",
    administrative_info: "管理者情報",
    administrative_name: "管理者",
    phone: "電話番号",
    post_code: "郵便番号",
    geo_ip_info: "Geo IP情報",
    registered_date: "登録日",
    registrant: "登録者",
    registrant_address: "登録者アドレス",
    updated_date: "最近の更新日",
    expiration_date: "満了日",
    name_servers: "ネームサーバー",
    ip_address: "ipアドレス",
    nation: "国",
    host_name: "ホスト名",
    screenshot_none: "スクリーンショットの画像がありません。",
    geo_ip_none: "Geo IP情報がありません",
    host_ip_none: "ホストIP情報がありません。",
    domain_none: "ドメイン情報がありません。",
    certificate_none: "証明書情報がありません。",
    detection_none: "検出情報はありません。",
    hash_none: "ハッシュ情報は存在しません。",
    certificate_title: "証明書",
    show_brief: "簡潔に表示",
    show_all: "すべて表示",
    summary: "概要",
    resources: "リソース",
    links: "リンク",
    whitelist_add: "WL 追加",
    manual_add: "マニュアル追加",
    classification: {
      phishing: "フィッシング",
      fraud: "詐欺",
      gambling: "ギャンブル",
      adult: "アダルト",
      malware: "マルウェア",
      illegal: "違法",
      normal: "正常",
      inaccessible: "アクセス不可",
      unknown: "不明",
      privacy: "個人情報",
    },
  },
  resources: {
    tips: "該当するハッシュファイルを含むDomain（URL）情報を表示します。",
    resource_info: "リソース情報",
  },
  scanning: {
    url_scanning: "URLをスキャンしています。",
    wait_a_moment: "しばらくお待ちください。",
  },
  audit: {
    title: "Audit Log",
    changes: "監査ログ",
  },
  news: {
    enter_title: "タイトルを入力してください",
    enter_link: "リンクを入力してください",
    enter_published_date: "公開日を入力してください",
    enter_source: "出典を入力してください",
    enter_lang: "言語を選択してください",
  },
  detection: {
    enter_item_id: "IDを入力してください。",
    enter_tags: "タグを選択してください。",
    enter_description: "説明を入力してください。",
    enter_domain: "ドメインを入力してください。",
    enter_url: "URLを入力してください。",
    enter_source: "ソースを入力してください。",
    enter_source_id: "ソースIDを入力してください。",
    enter_classification: "分類を入力してください。",
    enter_judgment_basis: "判断基準を入力してください。",
    enter_target: "ターゲットを入力してください。",
    include_subdomain: "サブドメインを含む",
    whitelist_judgment_basis:
      "悪意のある行為やフィッシングの明確な証拠がありません。したがって、正常なサイトと判断します。",
  },
  update: {
    before: "更新前",
    after: "更新後",
    screenshot_dialog_title: "スクリーンショット編集",
    screenshot_dialog_no_image: "画像がありません",
    screenshot_dialog_select_image: "画像を選択してください",
    screenshot_dialog_choose_image: "画像選択",
    screenshot_dialog_confirm_download: "画像をダウンロードしますか？",
    screenshot_dialog_drop_image: "または、画像をここにドロップしてください。",
    no_file_selected: "選択されたファイルがありません",
  },
  support: {
    process_time: "処理時間",
    title: "NICE問い合わせ",
    assignee: "担当者",
    assign_to_me: "自分に割り当て",
    status: "状態",
    status_wait: "処理待ち",
    status_done: "完了",
    status_cancel: "キャンセル",
    change_to_cancel: "キャンセルに変更",
    change_to_wait: "処理待ちに変更",
    email: "メール",
    inquiry_type: "問い合わせ種類",
    transaction_id: "トランザクションID",
    inquiry_date: "問い合わせ日付",
    inquiry_content: "問い合わせ内容",
    write_answer: "回答作成",
    cancel: "キャンセル",
    send: "送信",
    answer_time: "回答時間",
    answer_content: "回答内容",
    write_comment: "コメント作成",
    comment: "コメント",
    enter_comment: "コメントを入力してください",
    enter_scan_result_url: "スキャン結果のURLを入力してください。",
    report_url: "通報URL",
    contact_url: "お問い合わせURL",
    type: {
      contact: "問い合わせの種類",
      nice: "問い合わせの種類",
      report: "通報の種類",
    },
    contents: {
      contact: "問い合わせ内容",
      nice: "問い合わせ内容",
      report: "通報内容",
    },
    dialog_title: {
      contact: "問い合わせ",
      report: "通報",
      nice: "NICE サポート",
    },
    company_name: "会社名",
    department: "部署名",
    name: "ニックネーム",
    mobile: "携帯電話番号",
    product_name: "製品名",
    unassigned: "未割り当て",
  },
};

export default search;
