const common = {
  ok: "확인",
  cancel: "취소",
  apply: "적용",
  view: "조회",
  edit: "수정",
  add: "추가",
  copy: "복사하기",
  detail: "상세보기",
  update: "갱신",
};

export default common;
