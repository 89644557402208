const menu = {
  dashboard: "Dashboard",
  scanResult: "Scan Result",
  management: "Management",
  account: "Account",
  support: "Support",
  status: "Status",
  dashboard2: "Dashboard 2",
  whiteList: "WhiteList",
  blackList: "BlackList",
  permutation: "Permutation",
  domain: "Domain",
  pageTitle: "Page Title",
  pattern: "Pattern",
  report: "Report",
  contact: "Contact",
  mypage: "MyPage",
  logout: "Logout",
  audit: "Audit Log",
  securityNews: "Security News",
  news: "News",
  blog: "Blog",
  nice: "NICE Support",
  scanrequest: "Scan Request",
  smsscan: "SMS",
  urlscan: "URL",
  scrap: "Scrap",
  urlscrap: "URL",
  linkscrap: "Link",
  resourcescrap: "Resource",
};

export default menu;
