const scrapRoutes = [
  {
    path: "/scrap/url",
    name: "urlscrap",
    component: () =>
      import(
        /* webpackChunckName: "urlrequest" */ "@/views/scrap/UrlScrapView.vue"
      ),
  },
  {
    path: "/scrap/link",
    name: "linkscrap",
    component: () =>
      import(
        /* webpackChunckName: "smsrequest" */ "@/views/scrap/LinkScrapView.vue"
      ),
  },
  {
    path: "/scrap/resource",
    name: "resourcescrap",
    component: () =>
      import(
        /* webpackChunckName: "smsrequest" */ "@/views/scrap/ResourceScrapView.vue"
      ),
  },
];

export default scrapRoutes;
