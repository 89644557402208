const dataTable = {
  top: {
    filter_clear: "초기화",
    placeholder: 'Search Field = "Value" 형식으로 검색어를 입력하세요',
  },
  table: {
    header: {
      name: "제목",
      date: "날짜",
      domain: "도메인",
      title: "제목",
      tag: "태그",
      detection_info: "탐지정보",
      resource_num: "리소스",
      link_num: "링크",
      country: "국가",
      published_date: "게시날짜",
      language: "언어",
      elapsed: "경과시간",
      message: "문자내용",
      classification: "분류",
      url_count: "URL 수",
      ip: "IP",
      source: "출처",
      id: "ID",
      url: "URL",
      api_key: "API 키",
      start_date: "시작날짜",
      end_date: "종료날짜",
      status: "상태",
      error: "오류",
      req_id: "요청 ID",
      url_id: "URL ID",
      favicon: "파비콘",
      headers: "헤더",
      screenshot: "스크린샷",
      page_url: "페이지 URL",
      anchor_text: "앵커 텍스트",
      selector: "셀렉터",
      data_md5: "MD5",
      type: "타입",
      content_type: "컨텐츠 타입",
      size: "크기",
      resource_url: "리소스 URL",
      source_id: "출처 ID",
      judgement_basis: "판단 근거",
      target: "대상",
      permutation: "퍼뮤테이션",
      disabled: "비활성",
      comment: "코멘트",
      version: "버전",
      description: "설명",
      registrant_name: "등록자",
      registrant_address: "등록자 주소",
      registered_date: "등록날짜",
      updated_date: "수정날짜",
      expiration_date: "만료날짜",
      authorized_agency: "인가 기관",
      dns_sec: "DNS SEC",
      dynamic_dns: "동적 DNS",
      email: "이메일",
      link: "링크",
      actor: "행위자",
      action: "행위",
      geo_ip: "Geo IP",
      user_info: "사용자 정보",
    },
    empty: "검색 결과가 없습니다.",
    loading: "데이터를 불러오고 있습니다. 잠시만 기다려주세요.",
    current_page_report:
      "전체 {totalRecords}개 중 {first} ~ {last}번째 데이터 출력",
  },
};

export default dataTable;
