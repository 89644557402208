const common = {
  ok: "OK",
  cancel: "Cancel",
  apply: "Apply",
  view: "View",
  edit: "Edit",
  add: "Add",
  copy: "Copy",
  detail: "View Details",
  update: "Update",
};
export default common;
